import { useState, useEffect } from 'react';
import { map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import PdfViewer from '../../PdfViewer/PdfViewer';
import './CardEvaluaciones.scss';

export default function CardEvaluaciones({ element, setShowModal, setTitleModal, setContentModal }) {

    const [dataElement, setDataElement] = useState([]);

    useEffect(() => {
        setDataElement(element);
    }, [element]);

    const onView = (titulo, archivo) => {
        setTitleModal(titulo);
        setContentModal(<PdfViewer archivo={archivo} />);
        setShowModal(true);
    }

    return (
        <div className="card-general">
            <h4>{dataElement.nivel}</h4>
            <Row>
                {
                    map(dataElement.elementos, item => (
                        <Col className="gy-3" lg={4} md={4} key={item.titulo}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{item.titulo}</Card.Title>
                                    <div className="card-general__buttons">
                                        <ButtonGroup >
                                            <Button className="rounded-pill" variant="" onClick={() => onView(item.titulo, item.archivo)} >
                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </Button>
                                            <Button className="rounded-pill" variant="" onClick={() => onView(item.subtitulo, item.subarchivo)} >
                                                <FontAwesomeIcon icon={faFileCircleCheck} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>

        </div>
    );
}

