import { useState, useEffect } from 'react';
import { db } from '../../utils/Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { BrowserRouter as Router } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';
import TopBar from '../../components/TopBar/TopBar';
import Rutas from '../../routes/Routes';
import './User.scss';

export default function User({ user, setReloadApp }) {

    const [dataUser, setDataUser] = useState(null);

    useEffect(() => {

        const docRef = doc(db, 'Usuarios', user.uid);
        getDoc(docRef).then(response => {
            setDataUser(response.data());
        });

    }, [user]);


    return (
        <Router>
            <div className="layout-user container-fluid">
                <Row>
                    <Col className="bg-topbar" xl={12} lg={12} md={12} sm={12}>
                        <TopBar user={user} />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Rutas user={user} setReloadApp={setReloadApp} />
                    </Col>
                </Row>
            </div>
        </Router>
    );
}