import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/Firebase';
import { doc, getDoc } from 'firebase/firestore';

import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';
import SingOutModal from '../Modal/SingOutModal/SingOutModal';
import NoUser from '../../assets/avatars/user.png';
import LogoApp from '../../assets/logos/topbar.png';
import './TopBar.scss';


function TopBar({ user }) {

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [userName, setUserName] = useState(null)

    useEffect(() => {

        const docRef = doc(db, 'Usuarios', user.uid);
        getDoc(docRef).then(response => {
            const data = response.data();
            setUserName(data.nombre);
        });

    }, [user]);

    const handlerModalSingOut = () => {
        setTitleModal("Cerrar Sesión");
        setContentModal(<p>¿Estas Seguro de cerrar sesión?</p>);
        setShowModal(true);
    };


    return (
        <div className="topbar">
            <div className="container-topbar">
                <div className="container-topbar__logo">
                    <Image src={LogoApp} alt="logo" />
                </div>
                <div className="container-topbar__settings">
                    <div className="container-topbar__settings-user">
                        <Link to="/settings">
                            <Image src={user.photoURL ? user.photoURL : NoUser} />
                            <span>{userName}</span>
                        </Link>
                    </div>
                    <span><FontAwesomeIcon icon={faSignOut} onClick={handlerModalSingOut} /></span>
                </div>
            </div>

            <SingOutModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </SingOutModal>
        </div>
    );
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component {...props} router={{ location, navigate, params }} />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(TopBar);