import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './ReturnBar.scss';

function ReturnBar({ router }) {

    const { navigate } = router;

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="return-bar">
            <div className="return-bar__icon">
                <FontAwesomeIcon icon={faChevronLeft} onClick={goBack} />
            </div>
        </div>
    );
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component {...props} router={{ location, navigate, params }} />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(ReturnBar);