import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { map } from 'lodash';

import TripticosA from '../../../documents/Recursos/Tripticos/tripticosA.json';
import TripticosB from '../../../documents/Recursos/Tripticos/tripticosB.json';
import TripticosC from '../../../documents/Recursos/Tripticos/tripticosC.json';
import TripticosD from '../../../documents/Recursos/Tripticos/tripticosD.json';

import { Col, Container, Row, Spinner } from 'react-bootstrap';
import ReturnBar from '../../../components/ReturnBar/ReturnBar';
import CardItem from '../../../components/Cards/CardItem/CardItem';
import PdfModal from '../../../components/Modal/PdfModal/PdfModal';
import './Tripticos.scss';

function Tripticos({ router }) {

    const { params } = router;

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const [tripticos, setTripticos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        switch (params?.id) {
            case 'A':
                setTripticos(TripticosA);
                break;
            case 'B':
                setTripticos(TripticosB);
                break;
            case 'C':
                setTripticos(TripticosC);
                break;
            case 'D':
                setTripticos(TripticosD);
                break;
            default:
                setTripticos([]);
                break;
        }

        setIsLoading(false);

    }, [params]);

    return (
        <div className="tripticos">
            <ReturnBar />
            {
                isLoading ? (
                    <div className="tripticos__loading">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Container>
                        <h2 className="tripticos__titulo">Tripticos</h2>
                        <Row>
                            {
                                map(tripticos, items => (
                                    <Col lg={12} md={12} className="gy-5" key={items.id} >
                                        <CardItem
                                            element={items}
                                            setShowModal={setShowModal}
                                            setTitleModal={setTitleModal}
                                            setContentModal={setContentModal}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                )
            }

            <PdfModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </PdfModal>
        </div>
    );
}


function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component {...props} router={{ location, navigate, params }} />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(Tripticos);