import { Button, Modal } from "react-bootstrap";

import './BasicModal.scss';


export default function BasicModal({ show, setShow, title, children }) {

    const handleClose = () => {
        setShow(false);
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} className="basic-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    );
}