import { Modal } from 'react-bootstrap';
import './PdfModal.scss';

export default function PdfModal({ show, setShow, title, children }){

    const handleClose = () => {
        setShow(false);
    }

    return (
        <div>
            <Modal className="basic-modal" show={show} size="xl" onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    );
}