import { useState, useEffect } from 'react';
import { map } from 'lodash'

import ingresoDB from '../../../documents/Bachillerato/Ingreso/ingreso.json';
import simuladosDB from '../../../documents/Bachillerato/Ingreso/simulados.json';

import { Col, Container, Row, Spinner } from 'react-bootstrap';
import ReturnBar from '../../../components/ReturnBar/ReturnBar';
import CardIngreso from '../../../components/Cards/CardIngreso/CardIngreso';
import CardSimulados from '../../../components/Cards/CardSimulados/CardSimulados';
import PdfModal from '../../../components/Modal/PdfModal/PdfModal';
import './Ingreso.scss';

export default function IngresoBachillerato() {

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setData(ingresoDB);
        setData2(simuladosDB);
        setIsLoading(false);
    }, []);

    return (
        <div className="ingreso">
            <ReturnBar />
            {
                isLoading ? (
                    <div className="ingreso__loading">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Container>
                        <h2 className="ingreso__titulo">Ingreso Bachillerato</h2>
                        <Row>
                            {
                                map(data, items => (
                                    <Col lg={12} md={12} className="gy-5" key={items.id} >
                                        <CardIngreso
                                            element={items}
                                            setShowModal={setShowModal}
                                            setTitleModal={setTitleModal}
                                            setContentModal={setContentModal}
                                        />
                                    </Col>
                                ))

                            }

                            {
                                map(data2, items => (
                                    <Col lg={12} md={12} className="gy-5" key={items.id} >
                                        <CardSimulados
                                            element={items}
                                            setShowModal={setShowModal}
                                            setTitleModal={setTitleModal}
                                            setContentModal={setContentModal}
                                        />
                                    </Col>
                                ))

                            }
                        </Row>
                    </Container>
                )
            }

            <PdfModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </PdfModal>
        </div>
    );
}