import { useState, useEffect } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { db } from '../../utils/Firebase';
import { doc, getDoc } from 'firebase/firestore';

import LicenciaA from '../../documents/Licencias/licenciaA.json';
import LicenciaB from '../../documents/Licencias/licenciaB.json';
import LicenciaC from '../../documents/Licencias/licenciaC.json';
import LicenciaD from '../../documents/Licencias/licenciaD.json';

import { Accordion, Card, Col, Row, Spinner } from 'react-bootstrap';
import Recursos from '../../assets/tablero/recursos.png';
import Preescolar from '../../assets/tablero/preescolar.png';
import Primaria from '../../assets/tablero/primaria.png';
import Secundaria from '../../assets/tablero/secundaria.png';
import Bachillerato from '../../assets/tablero/bachillerato.png';
import Universidad from '../../assets/tablero/universidad.png';
import Ingles from '../../assets/tablero/english.png';
import Frances from '../../assets/tablero/france.png';
import './Home.scss'

export default function Home({ user }) {

    const [licenciaType, setLicenciaType] = useState(null);
    const [itemsCategorys, setItemsCategorys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        const docRef = doc(db, 'Usuarios', user.uid);
        getDoc(docRef).then(response => {
            const data = response.data();
            setLicenciaType(data.licencia);

            switch (data.licencia) {
                case 'A':
                    setItemsCategorys(LicenciaA);
                    break;
                case 'B':
                    setItemsCategorys(LicenciaB);
                    break;
                case 'C':
                    setItemsCategorys(LicenciaC);
                    break;
                case 'D':
                    setItemsCategorys(LicenciaD);
                    break;
                default:
                    setItemsCategorys([]);
                    break;
            }
            setIsLoading(false);
        });

    }, [user]);

    return (
        <div className="home container">
            {
                isLoading ? (
                    <div className="home__loading">
                        <Spinner animation="border" />
                    </div>
                )
                    : (
                        <Row className="justify-content-center g-5 mt-5" >
                            {
                                map(itemsCategorys, item => (
                                    <Col xl={4} lg={4} md={4} sm={4} key={item.id} >
                                        <CardHome category={item} licencia={licenciaType} />
                                    </Col>
                                ))
                            }
                        </Row>
                    )
            }
        </div>
    );
}



function CardHome({ category, licencia }) {

    const imgCard = () => {
        switch (category.img) {
            case "Recursos":
                return Recursos;
            case "Preescolar":
                return Preescolar;
            case "Primaria":
                return Primaria;
            case "Secundaria":
                return Secundaria;
            case "Bachillerato":
                return Bachillerato;
            case "Universidad":
                return Universidad;
            case "Ingles":
                return Ingles;
            case "Frances":
                return Frances;
            default:
                break;
        }
    }


    return (
        <>
            <Card className="home__card">
                <Card.Img variant="top" src={imgCard()} />
                <Card.Body>
                    <Card.Title className="text-center">{category.name}</Card.Title>
                    <Accordion flush>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Material</Accordion.Header>
                            <Accordion.Body>
                                <ul className="lista-materiales" >
                                    {
                                        map(category.lista, item => (
                                            <Link to={item.link === '/libros' || item.link === '/formatos' || item.link === '/tripticos' ?
                                                `${item.link}/${licencia}` :
                                                `${item.link}`}
                                                key={item.titulo}
                                            >
                                                <li>{item.titulo}</li>
                                            </Link>
                                        ))
                                    }
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Card.Body>
            </Card>
        </>
    );
}

