import { useState } from "react";
import { toast } from "react-toastify";
import { reauthenticate } from "../../utils/Api";
import alertErrors from "../../utils/AlertErrors";
import { auth } from "../../utils/Firebase";
import { signOut, updatePassword } from "firebase/auth";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeLowVision } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";


export default function UserPassword({ setShowModal, setTitleModal, setContentModal }) {

    const onEdit = () => {
        setTitleModal("Actualizar contraseña");
        setContentModal(<ChangePasswordForm setShowModal={setShowModal} />);
        setShowModal(true);
    }

    return (
        <div className="user-password">
            <h3>Contraseña: *** - *** - ***</h3>
            <Button variant="light" className="rounded-pill" onClick={onEdit}>Actualizar</Button>
        </div>
    );
}

function ChangePasswordForm({ setShowModal }) {

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ currentPassword: "", newPassword: "", repeatNewPassword: "" });

    const handlerShowPassword = num => {
    
        switch (num) {
            case 1:
                setShowPassword(!showPassword);
                break;
            case 2:
                setShowPassword2(!showPassword2);
                break;
            case 3:
                setShowPassword3(!showPassword3);
                break;
            default:
                break;
        }

    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (!formData.currentPassword || !formData.newPassword || !formData.repeatNewPassword) {
            toast.warning("Las contraseñas no pueden estar vacias");
        }
        else if (formData.currentPassword === formData.newPassword) {
            toast.warning("La nueva contraseña no puede ser la igual a la actual");
        }
        else if (formData.newPassword !== formData.repeatNewPassword) {
            toast.warning("Las nuevas contraseñas no son iguales");
        }
        else if (formData.newPassword.length < 6) {
            toast.warning("La contraseña tiene que tener minimo 6 caracteres");
        }
        else {

            setIsLoading(true);

            reauthenticate(formData.currentPassword).then(() => {

                updatePassword(auth.currentUser, formData.newPassword).then(() => {
                    toast.success("La contraseña se ha modicado con exito");
                    setIsLoading(false);
                    setShowModal(false);
                    signOut(auth);
                }).catch(err => {
                    alertErrors(err?.code);
                    setIsLoading(false);
                });

            }).catch(err => {

                alertErrors(err?.code);
                setIsLoading(false);

            });
        }

    }

    return (
        <div>
            <Form onSubmit={onSubmit}>

                <Form.Group className="mb-3">
                    <InputGroup className="mb-3 icon-inputs">
                        <InputGroup.Text>
                            {showPassword
                                ? <FontAwesomeIcon icon={faEyeLowVision} onClick={() => handlerShowPassword(1)} />
                                : <FontAwesomeIcon icon={faEye} onClick={() => handlerShowPassword(1)} />
                            }
                        </InputGroup.Text>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Contraseña actual"
                            onChange={e => setFormData({ ...formData, currentPassword: e.target.value })} />
                    </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                    <InputGroup className="mb-3 icon-inputs">
                        <InputGroup.Text>
                            {showPassword2
                                ? <FontAwesomeIcon icon={faEyeLowVision} onClick={() => handlerShowPassword(2)} />
                                : <FontAwesomeIcon icon={faEye} onClick={() => handlerShowPassword(2)} />
                            }
                        </InputGroup.Text>
                        <Form.Control
                            type={showPassword2 ? "text" : "password"}
                            name="password"
                            placeholder="Nueva contraseña"
                            onChange={e => setFormData({ ...formData, newPassword: e.target.value })} />
                    </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                    <InputGroup className="mb-3 icon-inputs">
                        <InputGroup.Text>{showPassword3
                            ? <FontAwesomeIcon icon={faEyeLowVision} onClick={() => handlerShowPassword(3)} />
                            : <FontAwesomeIcon icon={faEye} onClick={() => handlerShowPassword(3)} />
                        }
                        </InputGroup.Text>
                        <Form.Control
                            type={showPassword3 ? "text" : "password"}
                            name="password"
                            placeholder="Repite nueva contrseña"
                            onChange={e => setFormData({ ...formData, repeatNewPassword: e.target.value })} />
                    </InputGroup>
                </Form.Group>

                <div className="d-grid gap-2">
                    <Button className="rounded-pill mt-4" variant="light" type="submit" size="lg">{isLoading ? <Spinner animation="border" /> : "Actualizar"}</Button>
                </div>
            </Form>
        </div>
    );
}