import { toast } from "react-toastify";

export default function alertErrors(type) {

    switch (type) {

        case 'auth/wrong-password':
            toast.warning("La contraseña es incorrecta.");
            break;
        case 'auth/email-already-in-use':
            toast.warning("El nuevo email ya esta en uso.");
            break;
        case 'auth/user-not-found':
            toast.warning("El email no esta registrado");
            break;
        default:
            toast.warning("Error del servidor intentelo mas tarde.");
            break;
    }
}