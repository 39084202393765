import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { auth, storage } from '../../utils/Firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';

import { Image } from 'react-bootstrap';
import NoAvatar from '../../assets/avatars/user.png';


export default function UploadAvatar({ user, setReloadApp }) {


    const [avatarUrl, setAvatarUrl] = useState(user.photoURL);

    const onDrop = useCallback(acceptedFiles => {

        const file = acceptedFiles[0];
        setAvatarUrl(URL.createObjectURL(file));
        uploadImage(file).then(response => {

            updateProfile(auth.currentUser, {
                photoURL: response
            }).then(() => {
                setReloadApp(prevState => !prevState);
            }).catch((err) => {
                console.log(err);
                console.log(err.code);
                toast.error("Error al cambiar el avatar");
            });


        });

    });


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/jpeg, image/png',
        noKeyboard: true,
        onDrop
    });

    const uploadImage = async file => {

        // detectar el archivo
        const archivoLocal = file;
        //cargarlo a firebase storage
        const archivoRef = ref(storage, `avatar/${user.uid}`);
        await uploadBytes(archivoRef, archivoLocal);
        //obtener url de descargar
        const urlDescargar = getDownloadURL(archivoRef);

        return urlDescargar;

    };


    return (
        <div className="user-avatar" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <Image src={NoAvatar} />
            ) : (
                <Image src={avatarUrl ? avatarUrl : NoAvatar} />
            )}
        </div>
    );
}