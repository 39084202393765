import { useState, useEffect } from 'react';
import { map } from 'lodash';

import LibrosDB from '../../../documents/Recursos/Libros/libros.json';

import { Col, Container, Row, Spinner } from 'react-bootstrap';
import PdfModal from '../../../components/Modal/PdfModal/PdfModal';
import CardLibros from '../../../components/Cards/CardLibros/CardLibros';
import ReturnBar from '../../../components/ReturnBar/ReturnBar';
import './Libros.scss';

export default function Libros() {

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const [librosData, setLibrosData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setLibrosData(LibrosDB);
        setIsLoading(false);
    }, []);

    return (
        <div className="libros">
            <ReturnBar />
            {
                isLoading ? (
                    <div className="libros__loading">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Container>
                        <h2 className="libros__titulo">Libros de texto</h2>
                        <Row>
                            {
                                map(librosData, item => (
                                    <Col xl={12} lg={12} md={12} className="mb-5" key={item.id} >
                                        <h4 className="libros__subtitulo">{item.nivel}</h4>
                                        <CardLibros
                                            element={item}
                                            setShowModal={setShowModal}
                                            setTitleModal={setTitleModal}
                                            setContentModal={setContentModal}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                )
            }

            <PdfModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </PdfModal>
        </div>
    );
}