import { useState } from 'react';

import LogoApp from '../../assets/logos/intelimundo.png';
import BannerLogin from '../../assets/banners/auth.jpg';
import Logged from '../../components/Auth/Logged/Logged';
import Recover from '../../components/Auth/Recover/Recover';
import './Auth.scss';

export default function Auth() {

    const [selected, setSelected] = useState(null);

    const handlerSelected = () => {

        switch (selected) {
            case 'recover':
                return <Recover setSelected={setSelected} />;

            default:
                return <Logged setSelected={setSelected} />;

        }
    }

    return (
        <div className="auth" style={{ backgroundImage: `url('${BannerLogin}')` }} >
            <div className="auth__box">
                <div className="auth__box-logo">
                    <img src={LogoApp} alt="logo" />
                </div>
                {handlerSelected()}
            </div>
        </div>
    );
}