import { map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import PdfViewer from '../../PdfViewer/PdfViewer';
import './CardLibros.scss';

export default function CardLibros({ element, setShowModal, setTitleModal, setContentModal }) {

    return (
        <div className="libros-card">
            {
                map(element.elementos, item => (
                    <div key={item.nivel} >
                        <p className="libros-card__nameNivel">{item.nivel}</p>
                        <LayoutBooks
                            libros={item.libros}
                            setShowModal={setShowModal}
                            setTitleModal={setTitleModal}
                            setContentModal={setContentModal}
                        />
                    </div>
                ))
            }
        </div>
    );
}

function LayoutBooks({ libros, setShowModal, setTitleModal, setContentModal }) {


    const onView = (titulo, archivo) => {
        setTitleModal(titulo);
        setContentModal(<PdfViewer archivo={archivo} />);
        setShowModal(true);
    }

    return (
        <div>
            <Row>
                <Col xl={12} lg={12} md={12} >
                    <Row className="justify-content-center gy-3">
                        {
                            map(libros, libro => (
                                <Col xl={3} lg={3} md={3} key={libro.titulo}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{libro.titulo}</Card.Title>
                                            <div className="card-item__buttons">
                                                <ButtonGroup>
                                                    <Button className="rounded-pill" variant="" onClick={() => onView(libro.titulo, libro.archivo)} >
                                                        <FontAwesomeIcon icon={faFilePdf} />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
