import { Routes, Route } from 'react-router-dom';

//Pages
import Home from '../pages/Home/Home';

//Pages Recursos
import Libros from '../pages/Recursos/Libros/Libros';
import Tripticos from '../pages/Recursos/Tripticos/Tripticos';
import Formatos from '../pages/Recursos/Formatos/Formatos';

//Pages de Preescolar
import VeranoPreescolar from '../pages/Preescolar/VeranoPreescolar/VeranoPreescolar';
import DesarolloHabilidades from '../pages/Preescolar/DesarolloHabilidades/DesarolloHabilidades';
import Grafomotricidad from '../pages/Preescolar/Grafomotricidad/Grafomotricidad';
import Abecedario from '../pages/Preescolar/Abecedario/Abecedario';
import AbecedarioVisual from '../pages/Preescolar/AbecedarioVisual/AbecedarioVisual';
import Consonantes from '../pages/Preescolar/Consonantes/Consonantes';
import Grafias from '../pages/Preescolar/Grafias/Grafias';
import MateriaPunteado from '../pages/Preescolar/MaterialPunteado/MaterialPunteado';
import PalabrasColores from '../pages/Preescolar/PalabrasColores/PalabrasColores';
import AtencionMemoria from '../pages/Preescolar/AtencionMemoria/AtencionMemoria';
import LecturasCortas from '../pages/Preescolar/LecturasCortas/LecturasCortas';
import Sinfones from '../pages/Preescolar/Sinfones/Sinfones';
import MatematicasInicial from '../pages/Preescolar/MatematicasInicial/MatematicasInicial';

//Pages de Primaria
import VeranoPrimaria from '../pages/Primaria/VeranoPrimaria/VeranoPrimaria';
import Espanol from '../pages/Primaria/Espanol/Espanol';
import CompresionLectora from '../pages/Primaria/CompresionLectora/CompresionLectora';
import Ortografia from '../pages/Primaria/Ortografia/Ortografia';
import RegularizacionMate from '../pages/Primaria/RegularizacionMate/RegularizacionMate';
import Matematicas from '../pages/Primaria/Matematicas/Matematicas';
import CienciasNaturales from '../pages/Primaria/CienciasNaturales/CienciasNaturales';
import Evaluaciones from '../pages/Primaria/Evaluaciones/Evaluaciones';

//Pages de Secundaria
import VeranoSecundaria from '../pages/Secundaria/VeranoSecundaria/VeranoSecundaria';
import Biologia from '../pages/Secundaria/Biologia/Biologia';
import EspanolSecundaria from '../pages/Secundaria/Espanol/Espanol';
import MatematicasSecundaria from '../pages/Secundaria/Matematicas/Matematicas';
import RegularizacionMateSec from '../pages/Secundaria/RegularizacionMate/RegularizacionMate';
import FisicaSecundaria from '../pages/Secundaria/Fisica/Fisica';
import QuimicaSecundaria from '../pages/Secundaria/Quimica/Quimica';
import EvaluacionesSecundaria from '../pages/Secundaria/Evaluaciones/Evaluaciones';
import IngresoSecundaria from '../pages/Secundaria/Ingreso/Ingreso';

// Pages de Bachillerato
import MatematicasBachillerato from '../pages/Bachillerato/Matematicas/Matematicas';
import IngresoBachillerato from '../pages/Bachillerato/Ingreso/Ingreso';

//Pages de Universidad
import IngresoUniversidad from '../pages/Universidad/Ingreso/Ingreso';

//Pages English
import BooksEnglish from '../pages/English/Books/Books';
import MaterialEnglish from '../pages/English/Material/Material';
import Alphabet from '../pages/English/Alphabet/Alphabet';
import GrammarEnglish from '../pages/English/Grammar/Grammar';

//Pages Frances
import Abecedaire from '../pages/Frances/Abecedaire/Abecedaire';
import Materiel from '../pages/Frances/Materiel/Materiel';

import Settings from '../pages/Settings/Settings';

export default function Rutas({ user, setReloadApp }) {

    return (
        <Routes>
            <Route path='/' element={<Home user={user} />} />
            {/* Recursos */}
            <Route path='/libros/:id' element={<Libros />} />
            <Route path='/tripticos/:id' element={<Tripticos />} />
            <Route path='/formatos/:id' element={<Formatos />} />
            {/* Preescolar */}
            <Route path='/veranoPreescolar' element={<VeranoPreescolar />} />
            <Route path='/desarolloHabilidades' element={<DesarolloHabilidades />} />
            <Route path='/grafomotriciadad' element={<Grafomotricidad />} />
            <Route path='/abecedario' element={<Abecedario />} />
            <Route path='/abecedarioVisual' element={<AbecedarioVisual />} />
            <Route path='/consonantes' element={<Consonantes />} />
            <Route path='/grafias' element={<Grafias />} />
            <Route path='/materialPunteado' element={<MateriaPunteado />} />
            <Route path='/palabrasColores' element={<PalabrasColores />} />
            <Route path='/atencionMemoria' element={<AtencionMemoria />} />
            <Route path='/lecturasCortas' element={<LecturasCortas />} />
            <Route path='/sinfones' element={<Sinfones />} />
            <Route path='/matematicasInicial' element={<MatematicasInicial />} />
            {/* Primaria */}
            <Route path='/veranoPrimaria' element={<VeranoPrimaria />} />
            <Route path='/espanol' element={<Espanol />} />
            <Route path='/compresionLectora' element={<CompresionLectora />} />
            <Route path='/ortografia' element={<Ortografia />} />
            <Route path='/regularizacionMate' element={<RegularizacionMate />} />
            <Route path='/matematicas' element={<Matematicas />} />
            <Route path='/cienciasNaturales' element={<CienciasNaturales />} />
            <Route path='/evaluaciones' element={<Evaluaciones />} />
            {/* secundaria */}
            <Route path='/veranoSecundaria' element={<VeranoSecundaria />} />
            <Route path='/biologiaSecundaria' element={<Biologia />} />
            <Route path='/espanolSecundaria' element={<EspanolSecundaria />} />
            <Route path='/matematicasSecundaria' element={<MatematicasSecundaria />} />
            <Route path='/recMateSecundaria' element={<RegularizacionMateSec />} />
            <Route path='/fisicaSecundaria' element={<FisicaSecundaria />} />
            <Route path='/quimicaSecundaria' element={<QuimicaSecundaria />} />
            <Route path='/evaluacionesSecundaria' element={<EvaluacionesSecundaria />} />
            <Route path='/ingresoSecundaria' element={<IngresoSecundaria />} />
            {/* bachillerato */}
            <Route path='/matematicasBachillerato' element={<MatematicasBachillerato />} />
            <Route path='/ingresoBachillerato' element={<IngresoBachillerato />} />
            {/* Universidad */}
            <Route path='/ingresoUniversidad' element={<IngresoUniversidad />} />
            {/* English */}
            <Route path='/booksEnglish' element={<BooksEnglish />} />
            <Route path='/materialEnglish' element={<MaterialEnglish />} />
            <Route path='/alphabet' element={<Alphabet />} />
            <Route path='/grammarEnglish' element={<GrammarEnglish />} />
            {/* Frances */}
            <Route path='/abecedaire' element={<Abecedaire />} />
            <Route path='/materiel' element={<Materiel />} />
            {/* settings */}
            <Route path='/settings' element={<Settings user={user} setReloadApp={setReloadApp} />} />
        </Routes>
    );
}
