import { useState } from 'react';
import { auth } from '../../../utils/Firebase';
import { validateEmail } from '../../../utils/Validations';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';

import { Form, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons'
import './Logged.scss';


export default function Logged({ setSelected }) {

    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(defaultValueForm());
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);

    const handlerPassword = () => {
        setShowPassword(!showPassword);
    };

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value

        });
    };

    const onSubmit = event => {

        event.preventDefault();

        setFormError({});

        let errors = {};
        let formOk = true;

        if (!validateEmail(formData.email)) {
            errors.email = true;
            formOk = false;
        }

        if (formData.password.length < 6) {
            errors.password = true;
            formOk = false;
        }

        setFormError(errors);

        if (formOk) {
            setIsLoading(true);
            signInWithEmailAndPassword(auth, formData.email, formData.password).then(response => {
                setUser(response.user);
            }).catch(err => {
                handlerError(err.code);
            }).finally(() => {
                setIsLoading(false);
            });
        }

    };

    return (
        <div className="container logged">
            <Form onSubmit={onSubmit} onChange={onChange} >
                <Form.Group className="mb-3">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faAt} /></InputGroup.Text>
                        <FormControl
                            type="email"
                            placeholder="Correo electronico"
                            name="email"
                        />
                    </InputGroup>
                    {formError.email && (
                        <span className="error-text">
                            Por favor, introduce un correo valido
                        </span>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <InputGroup>
                        <InputGroup.Text>
                            {showPassword
                                ? <FontAwesomeIcon icon={faEyeLowVision} onClick={handlerPassword} />
                                : <FontAwesomeIcon icon={faEye} onClick={handlerPassword} />
                            }
                        </InputGroup.Text>
                        <FormControl
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            name="password"
                        />
                    </InputGroup>
                    {formError.password && (
                        <span className="error-text">
                            Por favor, la contraseña debe ser superior a 5 caracteres
                        </span>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <div className="password-restore">
                        <span onClick={() => setSelected("recover")} >¿olvidaste tu contraseña?</span>
                    </div>
                </Form.Group>
                <div className="mt-3 d-grid gap-2">
                    <Button variant="" type="submit" size="lg">
                        {isLoading ? <Spinner animation="border" /> : "Iniciar sesión"}
                    </Button>
                </div>
            </Form>
        </div>
    );

}

function handlerError(code) {

    switch (code) {
        case "auth/wrong-password":
            toast.warning("El usuario o la contraseña son incorrectos");
            break;

        case "auth/user-not-found":
            toast.warning("El usuario o la contraseña son incorrectos");
            break;

        default:
            break;
    }
}

function defaultValueForm() {

    return {
        email: "",
        password: ""
    }
}