import { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer'
import './PdfViewer.scss';

export default function PdfViewer({ archivo }) {

    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                path: '/webviewer',
                initialDoc: archivo,
                disabledElements: [
                    'contextMenuPopup',
                    'toolsOverlay',
                    'selectToolButton',
                    'fullscreenButton',
                    'downloadButton',
                ]
            },
            viewer.current,
        ).then((instance) => {
            
            const { documentViewer } = instance.Core;
            // you can now call WebViewer APIs here...
        });
    }, []);

    return (
        <div className="viewer-pdf">
            <div className="webviewer" ref={viewer}></div>
        </div>
    );
}