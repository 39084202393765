import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCDDwfgFJpTQ6igXWwqOTvUc2glIEW337M",
    authDomain: "cloudintelimundo-dcf84.firebaseapp.com",
    projectId: "cloudintelimundo-dcf84",
    storageBucket: "cloudintelimundo-dcf84.appspot.com",
    messagingSenderId: "894720133364",
    appId: "1:894720133364:web:44e822ddcb6d0b2842b6a1",
    measurementId: "G-TY1MDB34KN"
};


const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export default firebaseApp;