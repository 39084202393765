import { signOut } from "firebase/auth";
import { Button, Modal } from "react-bootstrap";
import { auth } from '../../../utils/Firebase';

import './SingOutModal.scss';


export default function ModalLogout({ show, setShow, title, children }) {

    const logout = () => {
        signOut(auth);
    }

    const handleClose = (e) => {

        if (e.target.name === "session") {
            setShow(false);
            logout();
        }
        else {
            setShow(false);
        }
    }

    return (
        <div>
            <Modal className="signOut-modal" show={show} onHide={handleClose} size="sm" centered>
                <Modal.Header>
                    <Modal.Title className="title-modal">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="rounded-pill" variant="light" name="cancelar" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button className="rounded-pill" variant="danger" name="session" onClick={handleClose}>
                        Cerrar sesión
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}