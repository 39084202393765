import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { auth } from './utils/Firebase';

import Auth from "./pages/Auth/Auth";
import User from './layouts/User/User';


export default function App() {

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadApp, setReloadApp] = useState(false);

  auth.onAuthStateChanged(currentUser => {

    setUser(currentUser);
    setIsLoading(false);

  });


  if (isLoading) {
    return null;
  }

  return (
    <>

      {!user ? <Auth /> : <User user={user} setReloadApp={setReloadApp} />}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />

    </>
  );
}



