import { useState, useEffect } from 'react';
import { map } from 'lodash'

import atencionMemoriaDB from '../../../documents/Preescolar/atencionMemoria.json';

import { Col, Container, Row, Spinner } from 'react-bootstrap';
import ReturnBar from '../../../components/ReturnBar/ReturnBar';
import CardGeneral from '../../../components/Cards/CardGeneral/CardGeneral';
import PdfModal from '../../../components/Modal/PdfModal/PdfModal';
import './AtencionMemoria.scss';

export default function AtencionMemoria() {

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setData(atencionMemoriaDB);
        setIsLoading(false);
    }, []);

    return (
        <div className="atencion-memoria">
            <ReturnBar />
            {
                isLoading ? (
                    <div className="atencion-memoria__loading">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Container>
                        <h2 className="atencion-memoria__titulo">Atención y Memoria</h2>
                        <Row>
                            {
                                map(data, items => (
                                    <Col lg={12} md={12} className="gy-5" key={items.id} >
                                        <CardGeneral
                                            element={items}
                                            setShowModal={setShowModal}
                                            setTitleModal={setTitleModal}
                                            setContentModal={setContentModal}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                )
            }

            <PdfModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </PdfModal>
        </div>
    );
}