import { useState, useEffect } from 'react';
import { map } from 'lodash';

//imagenes universidad
import MateUni from '../../../assets/ingreso/universidad/Matematicas.png';
import EspanolUni from '../../../assets/ingreso/universidad/Espanol.png';
import HistoriaMexUni from '../../../assets/ingreso/universidad/Historia-de-Mexico.png';
import HistoriaUnivUni from '../../../assets/ingreso/universidad/Historia-Universal.png';
import FisicaUni from '../../../assets/ingreso/universidad/Fisica.png';
import BiologiaUni from '../../../assets/ingreso/universidad/Biologia.png';
import GeografiaUni from '../../../assets/ingreso/universidad/Geografia.png';
import QuimicaUni from '../../../assets/ingreso/universidad/Quimica.png';

//imagenes secundaria y bachillerato
import MateMS from '../../../assets/ingreso/Bachillerato/Matematicas.png';
import HmateMs from '../../../assets/ingreso/Bachillerato/Habilidad-Matematica.png';
import EspanolMs from '../../../assets/ingreso/Bachillerato/Espanol.png';
import HverbMS from '../../../assets/ingreso/Bachillerato/Habilidad-Verbal.png';
import BiologiaMS from '../../../assets/ingreso/Bachillerato/Biologia.png';
import HmexMS from '../../../assets/ingreso/Bachillerato/Hsitporia-de-Mexico.png';
import HunivMS from '../../../assets/ingreso/Bachillerato/Hsitoria-Universal.png';
import FisicaMS from '../../../assets/ingreso/Bachillerato/Fisica.png';
import GeografiaMS from '../../../assets/ingreso/Bachillerato/Geografia.png';
import CivicaMS from '../../../assets/ingreso/Bachillerato/Civica-y-Etica.png';
import QuimicaMS from '../../../assets/ingreso/Bachillerato/Quimica.png';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Button } from 'react-bootstrap';
import PdfViewer from '../../PdfViewer/PdfViewer';
import './CardIngreso.scss';

export default function CardIngreso({ element, setShowModal, setTitleModal, setContentModal }) {

    const [dataElement, setDataElement] = useState([]);

    useEffect(() => {
        setDataElement(element);
    }, [element]);

    const getImage = (stringImage) => {

        switch (stringImage) {
            case "biologiaUni":
                return BiologiaUni;
            case "españolUni":
                return EspanolUni;
            case "fisicaUni":
                return FisicaUni;
            case "geografiaUni":
                return GeografiaUni;
            case "historiaMexUni":
                return HistoriaMexUni;
            case "historiaUnivUni":
                return HistoriaUnivUni;
            case "matematicasUni":
                return MateUni;
            case "quimicaUni":
                return QuimicaUni;

            case "biologiaMS":
                return BiologiaMS;
            case "españolMS":
                return EspanolMs;
            case "hverbMS":
                return HverbMS;
            case "fisicaMS":
                return FisicaMS;
            case "geografiaMs":
                return GeografiaMS;
            case "historiaMexMS":
                return HmexMS;
            case "historiaUnivMS":
                return HunivMS;
            case "matematicasMS":
                return MateMS;
            case "hmateMS":
                return HmateMs;
            case "quimicaMS":
                return QuimicaMS;
            case "civicaMS":
                return CivicaMS;
            default:
                break;
        }
    }


    const onView = (titulo, archivo) => {
        setTitleModal(titulo);
        setContentModal(<PdfViewer archivo={archivo} />);
        setShowModal(true);
    }

    return (
        <div className="card-ingreso">
            <h2 className="card-ingreso__titulo"> {element.titulo} </h2>
            <Row className="justify-content-center">
                {
                    map(dataElement.elementos, item => (
                        <Col className="gy-3" lg={4} md={4} key={item.token}>
                            <Card>
                                <Card.Img variant="top" src={getImage(item.image)} />
                                <Card.Body>
                                    <div className="card-ingreso__buttons">
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_1, item.archivo_1)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span> {item.titulo_1} </span>
                                        </div>
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_2, item.archivo_2)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span>{item.titulo_2}</span>
                                        </div>
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_3, item.archivo_3)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span>{item.titulo_3}</span>
                                        </div>
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_4, item.archivo_4)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span>{item.titulo_4}</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>

        </div>
    );
}

