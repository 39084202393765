import { useState, useEffect } from 'react';
import { map } from 'lodash';

//imagenes simuladores
import Simulador1 from '../../../assets/ingreso/simuladores/1.png';
import Simulador2 from '../../../assets/ingreso/simuladores/2.png';
import Simulador3 from '../../../assets/ingreso/simuladores/3.png';
import Simulador4 from '../../../assets/ingreso/simuladores/4.png';
import Simulador5 from '../../../assets/ingreso/simuladores/5.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col, Button } from 'react-bootstrap';
import PdfViewer from '../../PdfViewer/PdfViewer';
import './CardSimulados.scss';

export default function CardSimulados({ element, setShowModal, setTitleModal, setContentModal }) {

    const [dataElement, setDataElement] = useState([]);

    useEffect(() => {
        setDataElement(element);
    }, [element]);

    const getSimuladorImg = (StringImage) => {

        switch (StringImage) {
            case "Simulador1":
                return Simulador1;
            case "Simulador2":
                return Simulador2;
            case "Simulador3":
                return Simulador3;
            case "Simulador4":
                return Simulador4;
            case "Simulador5":
                return Simulador5;
            default:
                break;
        }
    }


    const onView = (titulo, archivo) => {
        setTitleModal(titulo);
        setContentModal(<PdfViewer archivo={archivo} />);
        setShowModal(true);
    }

    return (
        <div className="card-ingreso">
            <h2 className="card-ingreso__titulo"> {element.titulo} </h2>
            <Row className="justify-content-center">
                {
                    map(dataElement.elementos, item => (
                        <Col className="gy-3" lg={4} md={4} key={item.token}>
                            <Card>
                                <Card.Img variant="top" src={getSimuladorImg(item.image)} />
                                <Card.Body>
                                    <div className="card-ingreso__buttons">
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_1, item.archivo_1)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span> {item.titulo_1} </span>
                                        </div>
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_2, item.archivo_2)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span>{item.titulo_2}</span>
                                        </div>
                                        <div className='card-ingreso__buttons-item' onClick={() => onView(item.titulo_3, item.archivo_3)}>
                                            <Button className='rounded-circle' variant=""><FontAwesomeIcon icon={faBookmark} /></Button>
                                            <span>{item.titulo_3}</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>

        </div>
    );
}

