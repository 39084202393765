import { useState, useEffect } from 'react';
import { db } from '../../utils/Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadAvatar from '../../components/Settings/UploadAvatar';
import UserPassword from '../../components/Settings/UserPassword';
import BasicModal from '../../components/Modal/BasicModal/BasicModal';
import './Settings.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


export default function Settings({ user, setReloadApp }) {

    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const [userData, setUserData] = useState({});

    useEffect(() => {

        const docRef = doc(db, 'Usuarios', user.uid);
        getDoc(docRef).then(document => {

            const data = document.data();
            setUserData(data);
        });

    }, [user]);

    return (
        <div className="settings">
            <div className="settings__header">
                <h1>Configuración</h1>
                <Link to="/">
                    <FontAwesomeIcon icon={faTimes} />
                </Link>
            </div>

            <div className="avatar-name">
                <UploadAvatar user={user} setReloadApp={setReloadApp} />
                <div className="user-name">
                    <h2> {userData.nombre} </h2>
                    <h2> {userData.sucursal} </h2>
                    <h2> Licencia: {userData.licencia} </h2>
                </div>
            </div>

            <UserPassword setShowModal={setShowModal} setTitleModal={setTitleModal} setContentModal={setContentModal} />

            <BasicModal show={showModal} setShow={setShowModal} title={titleModal}>
                {contentModal}
            </BasicModal>
        </div>
    );
}