import { useState } from 'react';
import { auth } from '../../../utils/Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import alertErrors from '../../../utils/AlertErrors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import './Recover.scss';
import { toast } from 'react-toastify';


export default function Recover({ setSelected }) {

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ email: "" });

    const onSubmit = e => {
        e.preventDefault();

        setIsLoading(true);

        sendPasswordResetEmail(auth, formData.email).then(() => {

            toast.success("Se ha enviado un correo para restablecer la contraseña");
            setSelected(null);

        }).catch(err => {
            alertErrors(err.code);
            setIsLoading(false);
        });
    };

    return (
        <div className="container recover">
            <Form onSubmit={onSubmit} >
                <InputGroup className="mb-3">
                    <InputGroup.Text ><FontAwesomeIcon icon={faAt} /></InputGroup.Text>
                    <FormControl
                        type="email"
                        placeholder="Correo electronico"
                        name="email"
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </InputGroup>

                <Form.Group className="mb-3">
                    <div className="password-restore">
                        <span onClick={() => setSelected(null)} >Iniciar sesión</span>
                    </div>
                </Form.Group>

                <div className="mt-3 d-grid gap-2">
                    <Button variant="" size="lg" type="submit" >
                        {isLoading ? <Spinner animation="border" /> : "Restablecer"}
                    </Button>
                </div>
            </Form>
        </div>
    );
}